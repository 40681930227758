import React, { useEffect, useState, useContext } from 'react'
import "./ReportsUzunDonem.css";
import { GET_VALUES3 } from "../../graphql/Query"
import { useQuery } from '@apollo/client';
import axios from 'axios';
import _ from "lodash"
import Newspaper from "../../assets/image/newspaper.png"
import LinePlotChartReportLong from '../../Components/Charts/LinePlotChartReportLong/LinePlotChartReportLong';
import ChartTableReportLong from '../../Components/Table/ChartTableReportLong';
import baseUrl from "../../config";
import CountryContext from '../../Context/Country';


export default function ReportsUzunDonem() {

    const [reportData, setReportData] = useState([])
    const [dateValue, setDateValue] = useState([])
    const [changeDate, setChangeDate] = useState("")
    const [changeModel, setChangeModel] = useState("dnn")
    const [modifiedData, setModifiedData] = useState([]);
    const [createdAt, setCreatedAt] = useState([])
    const [reportData1, setReportData1] = useState([])
    const [newsLink, setNewsLink] = useState("")
    const { countryValue, setCountryValue } = useContext(CountryContext)


    const DateData = async () => {
        try {
            await axios.get(`${baseUrl}/returnLongTermResultDates/${countryValue}`)
                .then(response => setDateValue(response.data))

        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        DateData()
    }, [countryValue])



    useEffect(() => {
        const dataList = reportData?.map((item, index) => ({
            key: index + 1,
            period: item.period,
            //   model: item?.model,
            predictedValue: item?.predictedValue

        }));

        setModifiedData([...dataList]);
    }, [reportData, countryValue]);

    const { loading: loading, error: error, data: data } = useQuery(GET_VALUES3, {
        variables: {
            date: changeDate ? changeDate : dateValue[0]?.created_at,
            country: countryValue
        },
    });



    useEffect(() => {
        if (data) {
            setReportData(data.longTermResultsWithSpecificDate)

        }

    }, [data, countryValue])

    useEffect(() => {
        setChangeDate("")
    }, [countryValue])

    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${localStorage.getItem("token")}`
    //     }
    // }


    const handleDate = (e) => {
        setChangeDate(e.target.value)
    }

    useEffect(() => {
        // axios.post(`https://api.epias.com.tr/epias/exchange/transparency/bulletin/daily?date=${new Date().toISOString().slice(0, 10)}`)
        // axios.get("https://smartoptcorspolicy.herokuapp.com/http://sisecam-epf.eu-central-1.elasticbeanstalk.com/returnLinkToEPIASDailyBulletin")
        axios.get("https://smartoptcorspolicy.herokuapp.com/http://104.155.51.112/returnLinkToEPIASDailyBulletin")

            .then(response => setNewsLink(response.data))
            .catch(err => console.log(err))
    }, [])


    return (


        <div className='dashboard-container'>

            <div className='headerReports'>
                <label>Tahmin Tarihi : </label>
                <div>

                    <select onChange={handleDate}>
                        {
                            dateValue.map((item, index) => {
                                return <option key={index} value={item.created_at}>{item.created_at.slice(0, 10) + " " + item.created_at.slice(11, 16)}</option>
                            })
                        }

                    </select>

                </div>
                {
                    countryValue == "Türkiye" ?
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <p >EPİAŞ Günlük Bülten</p>
                            <a href={newsLink} target='_blank' >
                                <img src={Newspaper} alt="" width={30} height={30} />
                            </a>
                        </div>
                        : null
                }

            </div>
            <div className='charts-table-body'>
                <div className='table'>
                    <ChartTableReportLong veri={reportData} setVeri={setReportData} veri1={reportData1} changeDate={changeDate}/>
                </div>
                <div className='charts'>
                    <LinePlotChartReportLong veri={reportData} setVeri={setReportData} veri1={reportData1} />
                </div>
            </div>

        </div>
    )
}
